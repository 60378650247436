<template>
    <div class="land-share" :class="'size-' + size">
        <ul>
            <li class="Facebook">
                <a @click="share('Facebook')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Facebook</span>
                </a>
            </li>
            <li class="Twitter">
                <a @click="share('Twitter')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Twitter</span>
                </a>
            </li>
            <li class="Linkedin">
                <a @click="share('Linkedin')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Linkedin</span>
                </a>
            </li>
            <li class="Pinterest">
                <a @click="share('Pinterest')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Pinterest</span>
                </a>
            </li>
            <li class="Reddit">
                <a @click="share('Reddit')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Reddit</span>
                </a>
            </li>
            <li class="Wordpress">
                <a @click="share('Wordpress')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Wordpress</span>
                </a>
            </li>
            <li class="Blogger">
                <a @click="share('Blogger')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Blogger</span>
                </a>
            </li>
            <li class="Tumblr">
                <a @click="share('Tumblr')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Tumblr</span>
                </a>
            </li>
            <li class="Mix">
                <a @click="share('Mix')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Mix</span>
                </a>
            </li>
            <li class="Diigo">
                <a @click="share('Diigo')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Diigo</span>
                </a>
            </li>
            <li class="Flipboard">
                <a @click="share('Flipboard')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Flipboard</span>
                </a>
            </li>
            <li class="Instagram">
                <a @click="share('Instagram')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Instagram</span>
                </a>
            </li>
            <li class="Vkontakte">
                <a @click="share('Vkontakte')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Vkontakte</span>
                </a>
            </li>
            <li class="Mewe">
                <a @click="share('Mewe')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Mewe</span>
                </a>
            </li>
            <li class="Trello">
                <a @click="share('Trello')" href="javascript:;">
                    <span class="icon"></span>
                    <span v-if="site_name" class="text">Trello</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data(){
        return {
            url: "",
            title: ""
        }
    },
    props: {
        url_share: {
            type: String,
            default: ""
        },
        title_share: {
            type: String,
            default: ""
        },
        site_name: {
            type:Boolean,
            default: true
        },
        size: {
            type:String,
            default: "small"
        }
    },

    methods: {
        share(type) {
            var that = this;
            var url_social = '';
            switch (type) {
                case "Facebook":
                    url_social = "https://www.facebook.com/sharer/sharer.php?u=" + that.url;
                    break;
                case "Twitter":
                    url_social = `https://twitter.com/intent/tweet?text=${that.title}&url=${that.url}`;
                    break;
                case "Linkedin":
                    url_social = `https://www.linkedin.com/shareArticle?mini=true&url=${that.url}&title=${that.title}`;
                    break;
                case "Pinterest":
                    var e=document.createElement('script' );
                    e.setAttribute('type','text/javascript' );
                    e.setAttribute('charset','UTF-8' );
                    e.setAttribute('src','//assets.pinterest.com/js/pinmarklet.js?r='+Math.random()*99999999);
                    document.body.appendChild(e)
                    break;
                case "Reddit":
                    url_social = `http://reddit.com/submit?url=${that.url}&title=${that.title}`;
                    break;
                case "Wordpress":
                    url_social = `https://www.addtoany.com/ext/wordpress/press_this?linkurl=${that.url}&linkname=${that.title}`;
                    break;
                case "Blogger":
                    url_social = `https://www.blogger.com/blog_this.pyra?t&u=${that.url}&n=${that.title}`;
                    break;
                case "Tumblr":
                    url_social = `http://www.tumblr.com/share?v=3&u=${that.url}&t=${that.title}`;
                    break;
                case "Mix":
                    url_social = `https://mix.com/mixit?url=${that.url}`;
                    break;
                case "Diigo":
                    url_social = `http://www.diigo.com/post?url=${that.url}&title=${that.title}`;
                    break;
                case "Flipboard":
                    url_social = `https://share.flipboard.com/bookmarklet/popout?v=2&url=${that.url}&title=${that.title}`;
                    break;
                case "Instagram":
                    url_social = `https://www.instagram.com/`;
                    break;
                case "Vkontakte":
                    url_social = `http://vkontakte.ru/share.php?&url=${that.url}`;
                    break;
                case "Mewe":
                    url_social = `https://mewe.com/share?link=${that.url}`;
                    break;
                case "Trello":
                    url_social = `https://trello.com/add-card?mode=popup&url=${that.url}&name=${that.title}`;
                    break;
            }

            if (url_social != '') {
                window.open(
                    url_social,
                    "popUpWindow",
                    "height=400,width=600,left=400,top=100,resizable,scrollbars,toolbar=0,personalbar=0,menubar=no,location=no,directories=no,status"
                );
            }
        }
    },

    created(){
        if (this.url_share != "") {
            this.url = this.url_share;
        } else {
            this.url = window.location.href;
        }
        if (this.title_share != "") {
            this.title = this.title_share;
        } else {
            this.title = "";
        }
    }
}
</script>


<style lang="less">
    @widthIcon: 26px;
    @heightIcon: 26px;

    @widthIconM: 32px;
    @heightIconM: 32px;   
    .land-share {
        font-family: "Arial";
        &.size-medium {
            .icon {
                width: @widthIconM;
                height: @heightIconM;
            }   
        }
        ul {
            list-style: none;
            margin: auto -5px;
            padding:  0;
            overflow: hidden;
            li {
                float: left;
                padding: 5px 5px;
                display: block;
                text-align: center
            }
        }
        a {
            display: block;
            border-radius: 25px;
            color: #0767b3;
            font-weight: bold;
            font-size: 13px;
        }
        .text {
            padding-left: 0px;
            display: inline-block;
            text-shadow: 0 0 1px #ffffff;
            font-size: 12px;
            
        }
        .icon {
            display: block;
            width: @widthIcon;
            height: @heightIcon;
            border-radius: 50%;
            background-color: #29435D;
            margin: auto;
        }

        .Facebook {
            .icon {
                background-color: #3c589a;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Facebook.svg");
            }
            a {
                
                color: #3c589a
            }
        }
        .Twitter {
            .icon {
                background-color: #55acee;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Twitter.svg");
            }
            a {
                
                color: #55acee
            }
        }
        .Linkedin {
            .icon {
                background-color: #0076b5;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Linkedin.svg");
            }
            a {
                
                color: #0076b5
            }
        }
        .Pinterest {
            .icon {
                background-color: #cc2329;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Pinterest.svg");
            }
            a {
                
                color: #cc2329
            }
        }
        .Reddit {
            .icon {
                background-color: #ff5700;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Reddit.svg");
            }
            a {
                
                color: #ff5700
            }
        }
        .Wordpress {
            .icon {
                background-color: #464646;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Wordpress.svg");
            }
            a {
                
                color: #464646
            }
        }
        .Blogger {
            .icon {
                background-color: #fda351;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Blogger.svg");
            }
            a {
                
                color: #fda351
            }
        }
        .Tumblr {
            .icon {
                background-color: #fda350;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Tumblr.svg");
            }
            a {
                
                color: #fda350
            }
        }
        .Mix {
            .icon {
                background-color: #ff8226;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Mix.svg");
            }
            a {
                
                color: #ff8226
            }
        }
        .Diigo {
            .icon {
                background-color: #498aca;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Diigo.svg");
            }
            a {
                
                color: #498aca
            }
        }
        .Flipboard {
            .icon {
                background-color: #cc0000;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Flipboard.svg");
            }
            a {
                
                color: #cc0000
            }
        }
        .Instagram {
            .icon {
                background-color: #527fa4;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Instagram.svg");
            }
            a {
                
                color: #527fa4
            }
        }
        .Vkontakte {
            .icon {
                background-color: #5e84ac;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Vkontakte.svg");
            }
            a {
                
                color: #5e84ac
            }
        }
        .Mewe {
            .icon {
                background-color: #007da1;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Mewe.svg");
            }
            a {
                
                color: #007da1
            }
        }
        .Trello {
            .icon {
                background-color: #1089ce;
                background-image: url("https://giaodien.muabannhanh.com/svg-social/Trello.svg");
            }
            a {
                
                color: #1089ce
            }
        }
    }
</style>